.warehouse-bid__title {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #21201F;
  margin-bottom: 30px;
  display: block;
}

.warehouse-bid__group {
  display: flex;
  align-items: flex-start;

  & + & {
    margin-top: 24px;
  }

  @media (max-width: 768px) {
    display: block;
  }
}

.warehouse-bid__group-title {
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #21201F;
  width: 100%;
  max-width: 150px;
  margin-right: 10px;
  flex: none;

  @media (max-width: 768px) {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.warehouse-bid__group-content {
  width: 100%;
}

.warehouse-bid__group-row {
  display: flex;
  & + & {
    margin-top: 8px;
  }
  & > * {
    width: 100%;
  }
  & > * + * {
    margin-top: 0 !important;
    margin-left: 8px;
  }

  @media (max-width: 768px) {
    display: block;

    & > * + * {
      margin-top: 8px !important;
      margin-left: 0;
    }
  }
}

.warehouse-bid__group-block {
  & + & {
    margin-top: 8px;
  }

  &.error {
    .warehouse-bid__group-input,
    .warehouse-bid__group-select {
      background-color: rgba(#FC5230, 10%);
    }

    .warehouse-bid__group-input {
      background-image: url("data:image/svg+xml,%3Csvg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.8125 19.0654H17.8125C19.5215 19.0654 20.6152 17.8057 20.6152 16.2529C20.6152 15.7842 20.498 15.3057 20.2441 14.8662L12.7246 1.76074C12.1973 0.823242 11.2598 0.344727 10.3125 0.344727C9.36523 0.344727 8.41797 0.833008 7.89062 1.76074L0.371094 14.876C0.117188 15.3154 0 15.7842 0 16.2529C0 17.8057 1.10352 19.0654 2.8125 19.0654ZM2.99805 17.1904C2.39258 17.1904 1.99219 16.6924 1.99219 16.1553C1.99219 15.999 2.01172 15.8037 2.10938 15.6279L9.43359 2.80566C9.62891 2.47363 9.9707 2.31738 10.3125 2.31738C10.6543 2.31738 10.9863 2.47363 11.1719 2.80566L18.5059 15.6475C18.5938 15.8135 18.6328 15.999 18.6328 16.1553C18.6328 16.6924 18.2227 17.1904 17.6172 17.1904H2.99805ZM10.3125 12.2881C10.8496 12.2881 11.1621 11.9854 11.1719 11.4189L11.3184 6.72168C11.3379 6.14551 10.9082 5.73535 10.3027 5.73535C9.69727 5.73535 9.27734 6.13574 9.29688 6.71191L9.43359 11.4287C9.45312 11.9854 9.76562 12.2881 10.3125 12.2881ZM10.3125 15.5303C10.9473 15.5303 11.4648 15.0713 11.4648 14.4561C11.4648 13.8311 10.957 13.3818 10.3125 13.3818C9.67773 13.3818 9.16016 13.8408 9.16016 14.4561C9.16016 15.0713 9.6875 15.5303 10.3125 15.5303Z' fill='%23FC5230'/%3E%3C/svg%3E%0A");
      background-position: 95%;
      background-repeat: no-repeat;

      &[type="date"] {
        background-image: none;
      }
    }
    
    .warehouse-bid__group-inputs {
      input {
        border-bottom-color: rgba(#FC5230, 50%);
      }
    }
  }
}

.warehouse-bid__group-label {
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #21201F;
  display: block;
  margin-bottom: 4px;
}

.warehouse-bid__group-input,
.warehouse-bid__group-select {
  display: block;
  width: 100%;
  background-color: #F5F4F2;
  border-radius: 16px;
  height: 48px;
  padding: 0 16px;
  cursor: pointer;
  color: #222;
  font-family: inherit;
}

.warehouse-bid__group-select {
  appearance: none;
  -moz-appearance:none;
  -webkit-appearance:none;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5 1L6 5.5L1.5 1' stroke='%2321201F' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  background-position: 96%;
  background-repeat: no-repeat;
}

.warehouse-bid__group-inputs {
  display: flex;
  justify-content: space-between;
  position: relative;

  input {
    height: 50px;
    border-bottom: 1px solid #D2D0CC;

    &.w-30 {
      width: 33%;
    }

    &.w-100 {
      width: 100%;
    }
  }

  .warehouse-bid__group-label {
    position: absolute;
    font-size: 13px;
    line-height: 14px;
    color: #9E9B98;
  }
}

.warehouse-bid__group-error {
  font-size: 13px;
  line-height: 15px;
  color: #FC5230;
  display: block;
  margin-top: 2px;
}

.warehouse-bid__actions {
  display: flex;
  align-items: center;
  margin-top: 40px;

  @media (max-width: 768px) {
    display: block;
  }
}

.warehouse-bid__steps {
  width: 100%;
  max-width: 150px;
  margin-right: 10px;
  font-size: 14px;
  line-height: 18px;
  color: #1F2021;

  @media (max-width: 768px) {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.warehouse-bid__steps-progress {
  width: 100%;
  max-width: 84px;
  display: block;
  height: 5px;
  background-color: #F7F9FA;
  border-radius: 5px;
  position: relative;
  margin-top: 6px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 100%;
    border-radius: 5px;
    background-color: #FCE45E;
  }

  &.w-50 {
    &::before {
      width: 50%;
    }
  }

  &.w-100 {
    &::before {
      width: 100%;
    }
  }
}

.warehouse-bid__button {
  width: 100%;
  justify-content: center;
}