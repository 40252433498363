
.app-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  background: rgba(#70747F, .4);
}

.app-modal-content {
  padding: 40px;
  background: #FFFFFF;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 585px;
  max-height: 95vh;
  overflow-y: auto;
  @media (max-width: 768px) {
    padding: 20px 20px 40px;
    max-width: calc(100vw - 20px);
  }
}

.app-modal-content__clear {
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  top: 30px;
  right: 30px;
  transition: all .2s;
  svg {
    fill: #C6C6CE;
  }
  &:hover {
    svg {
      fill: #222222;
    }
  }
}