.load-state {
  text-align: center;
}

.load-state__icon {
  margin-bottom: 8px;
}

.load-state__title {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
  display: block;
  margin-bottom: 8px;

  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 24px;
  }
}

.load-state__text {
  font-size: 20px;
  line-height: 32px;
  color: #000000;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 18px;
  }
}

.load-state__button {
  margin: 32px auto 0;
  width: 100%;
  max-width: 320px;
  justify-content: center;
}