.main-header {
  background: #FFFFFF;
  box-shadow: 0px 2px 2px rgba(112, 116, 127, 0.05), 0px 4px 4px rgba(112, 116, 127, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;

  @media (min-width: 769px) {
    &.map, &.main {
      .main-header__nav a {
        color: #70747F;

        &.active {
          color: #222 !important;
          cursor: default;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .container {
      padding: 0 12px;
    }
  }
}

.main-header__wrap {
  display: flex;
  align-items: center;
  height: 90px;
  @media (max-width: 768px) {
    height: 48px;
  }
}

.main-header__menu {
  display: none;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 48px;
  height: 48px;
  margin-left: auto;
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
    fill: #000;
  }
  @media (max-width: 768px) {
    display: flex;
  }
}

.main-header__logo {
  svg {
    width: 130px;
  }
}

.main-header__region {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
  background-color: transparent;
  margin-right: auto;
  margin-left: 16px;
  svg {
    margin-right: 14px;
  }
  @media (max-width: 768px) {
    font-size: 12px;
    margin-right: 16px;
    svg {
      width: 14px;
      height: 14px;
      margin-right: 5px;
    }
  }
}

.main-header__nav {
  display: flex;
  align-items: center;
  a {
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    color: #222;
    padding: 10px;
    &:hover {
      color: #000 !important;
    }
  }
  @media (max-width: 768px) {
    display: none;
    background-color: #fff;
    position: fixed;
    top: 48px;
    left: 0;
    right: 0;
    bottom: 0;
    flex-direction: column;
    padding: 0 12px 60px;
    &.open {
      display: flex !important;
    }
    a,
    a:visited,
    a:focus,
    a:hover {
      text-align: left;
      width: 100%;
      color: #222 !important;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      border-bottom: 1px solid #2D2D2D;
      height: 80px;
      display: flex;
      align-items: center;
      &:hover {
        color: #fff;
      }
    }
  }
}

.main-header__button {
  margin-left: 10px;
  @media (max-width: 768px) {
    margin-top: auto;
    width: 100%;
    max-width: 480px;
    min-height: 56px;
    justify-content: center;
  }
}

.select-region-content__title {
  margin-top: 40px;
  margin-bottom: 50px;
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  color: #222222;
  @media (max-width: 768px) {
    font-size: 28px;
    line-height: 34px;
    margin: 20px 0;
  }
  @media (max-width: 480px) {
    font-size: 18px;
    line-height: 26px;
  }
}

.main-header__signin-modal {
  height: 95vh;
}