@font-face {
  font-family: 'Yandex Sans Text';
  src: url('./fonts/YSText-Regular.woff2') format('woff2'),
      url('./fonts/YSText-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Yandex Sans Text';
  src: url('./fonts/YSText-Medium.woff2') format('woff2'),
      url('./fonts/YSText-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Yandex Sans Text';
  src: url('./fonts/YSText-Bold.woff2') format('woff2'),
      url('./fonts/YSText-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

*, *:after, *:before {
  vertical-align: baseline;
  font-size: 100%;
  border: 0 none;
  outline: 0 none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,body {
	width: 100%;
	height: 100%;
	font-family: 'Yandex Sans Text', Arial, sans-serif;
  color: #222222;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
}

ul,li {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: black;
}

img {
  max-width: 100%;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

textarea {
  resize: none;
}

.container {
  width: 100%;
  max-width: 1440px;
  padding: 0 40px;
  margin: 0 auto;
  @media (max-width: 768px) {
    padding: 0 16px;
  }
}

.app-tooltip {
  font-size: 14px !important;
  border-radius: 8px !important;
  padding: 13px 8px !important;
  @media (max-width: 768px) {
    font-size: 12px !important;
    padding: 12px 6px !important;
  }
}