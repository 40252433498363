.map-page {
  height: calc(100vh - 90px);
  @media (max-width: 768px) {
    height: calc(100vh - 48px);
  }
}

.map-page__main {
  position: relative;
  height: 100%;
}

.map-page__block {
  z-index: 3;
  position: relative;
}

iframe.education-block {
  width: 100%;
  height: 100%;
}