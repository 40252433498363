
.main-block {
  padding: 40px 10px 40px 0;
  min-height: 370px;
  @media (max-width: 768px) {
    min-height: auto;
    padding-bottom: 0;
  }
}

.main-block__title {
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 32px;
  }
}

.main-block__subtitle {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
  }
}

.main-block__actions {
  display: flex;
  & > .btn + .btn {
    margin-left: 16px;
    @media (max-width: 768px) {
      margin-left: 8px;
    }
  }
}

.main-block__select {
  max-height: calc(100% - 160px);
}