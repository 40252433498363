.main__hero {
  background: url('./bg.jpg') center no-repeat;
  background-size: cover;
  padding: 80px 0 170px;
  color: #fff;
  position: relative;
  z-index: 0;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 20%);
    z-index: -1;
  }



  @media (max-width: 768px) {
    padding: 60px 0 40px;
    min-height: 300px;
  }
}

.main__title {
  font-weight: bold;
  font-size: 100px;
  line-height: 120px;
  margin-bottom: 20px;
  max-width: 730px;
  @media (max-width: 768px) {
    font-size: 52px;
    line-height: 56px;
    margin-bottom: 12px;
  }
}

.main__descr {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  max-width: 790px;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 18px;
    max-width: 480px;
  }
}

.main__footer {
  padding-top: 70px;
  @media (max-width: 768px) {
    padding-top: 30px;
  }
}

.main__footer-title {
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 80px;
  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 25px;
  }
}

.main__footer-list {
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    display: block;
  }
}

.main__footer-item {
  font-size: 24px;
  line-height: 32px;
  color: #222222;
  max-width: 380px;

  svg {
    margin-bottom: 10px;
    height: 60px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;

    & + & {
      margin-top: 40px;
    }

    svg {
      height: 24px;
      width: 24px;
      margin-bottom: 8px;
    }
  }
}

.copyright {
  margin-top: 100px;
  background: #302F2D;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  padding: 22px 40px;
}